import { DxColumn, DxExport, DxSummary, DxTotalItem } from 'devextreme-vue/ui/data-grid';
import DataGridFactory from '../../../components/datagrid-factory/index.vue';
import { documentoStore } from '../../../store/modules/documentos';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Produtos',
  setup() {
    const store = documentoStore()
    
    return {
      store
    }
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxExport,
    DxSummary,
    DxTotalItem
  }
})
